import { createMigrate } from 'redux-persist';

/**
 * Latest Migration Version is the current Redux RootState
 */

type GuestRootStateV1 = ModelPortalState;

const persistMigrations = {};

type MigrationState = GuestRootStateV1;

export const modelPortalMigrate = createMigrate<MigrationState>(persistMigrations);

export const modelPortalVersion = 1;
