import { FC, KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AccountTree } from '@mui/icons-material';
import { Sentry } from 'react-activity';
import { useStoreProjectMutation } from '@/redux/features/projectApi';

type AddProjectProps = {
    organization: Organization;
    fullSmallButton?: boolean;
    refetchProjects?: () => void;
};

const AddProject: FC<AddProjectProps> = ({ organization, fullSmallButton, refetchProjects }) => {
    const [createProject, { data: project, error, isSuccess, isLoading, reset }] = useStoreProjectMutation();
    const [name, setName] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [nameError, setNameError] = useState<string>('');
    const disabled = useMemo((): boolean => name.trim().length < 2, [name]);
    const navigate = useNavigate();

    const handleSubmit = () => {
        !disabled && createProject({ orgId: organization.id, name });
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
        e.key === 'Enter' && handleSubmit();
    };

    const handleClose = () => {
        setOpen(false);
        setName('');
        setNameError('');
    };

    useEffect(() => {
        if (isSuccess && project) {
            handleClose();
            reset();
            refetchProjects && refetchProjects();
            navigate(`/organizations/${organization.id}/projects/${project.id}`);
        }
    }, [isSuccess, project]);

    useEffect(() => {
        if (error) {
            const response = error as BasicError;
            setNameError(response.data.message);
        }
    }, [error]);

    return (
        <>
            {fullSmallButton ? (
                <Button
                    disabled={organization.is_locked}
                    fullWidth={true}
                    size={'small'}
                    startIcon={<AccountTree />}
                    variant={'text'}
                    color={'success'}
                    onClick={() => setOpen(true)}
                >
                    Add Project
                </Button>
            ) : (
                <Button
                    disabled={organization.is_locked}
                    size={'large'}
                    startIcon={<AccountTree />}
                    variant={'contained'}
                    color={'secondary'}
                    onClick={() => setOpen(true)}
                >
                    Add
                </Button>
            )}
            <Dialog
                PaperProps={{
                    elevation: 0,
                }}
                open={open}
                fullWidth={true}
                maxWidth={'sm'}
                onClose={handleClose}
            >
                <DialogTitle>Add a new project for {organization.name}:</DialogTitle>
                {isLoading || isSuccess ? (
                    <Grid padding={1} container display={'flex'} justifyContent={'center'}>
                        <Sentry style={{ fontSize: 30 }} />
                    </Grid>
                ) : (
                    <>
                        <DialogContent>
                            <TextField
                                error={!!nameError.length}
                                helperText={nameError.length ? nameError : ''}
                                autoFocus
                                margin={'dense'}
                                id={'project-name'}
                                label={'Project Name'}
                                type={'text'}
                                fullWidth
                                variant={'outlined'}
                                onChange={(e) => setName(e.target.value)}
                                onKeyDown={handleKeyDown}
                                value={name}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button variant={'contained'} color={'inherit'} onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button
                                disabled={disabled}
                                startIcon={<AccountTree />}
                                variant={'contained'}
                                color={'secondary'}
                                onClick={handleSubmit}
                            >
                                Add
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default AddProject;
