import { isMobile } from 'react-device-detect';
import { makeStyles } from 'tss-react/mui';
import { useAppSelector } from '@/redux/hooks';
import UserSidebar from '@/Layout/Sidebar/UserSidebar';
import {
    Avatar,
    Badge,
    Divider,
    ListItemAvatar,
    MenuItem,
    MenuList,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import userAvatar from '@/images/users.png';
import { FC, useEffect, useState } from 'react';
import { AdminPanelSettings } from '@mui/icons-material';
import { useLocation } from 'react-router';
import AdminMenu from '@/Layout/Sidebar/AdminMenu';

type NewAdminSidebarProps = {
    toggleDrawer?: () => void;
};

const AdminSidebar: FC<NewAdminSidebarProps> = ({ toggleDrawer }) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));
    const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false);
    const { classes } = useStyles({ isMobile, isSmall });
    const user = useAppSelector((state) => state.user);
    const location = useLocation();

    useEffect(() => {
        setUserMenuOpen(false);
    }, [location.pathname]);

    return (
        <Stack
            id={'sidebar'}
            className={classes.sidebar}
            divider={<Divider orientation={'vertical'} flexItem />}
            direction={'row'}
        >
            <div className={classes.leftColumn}>
                {user.data && (
                    <MenuList className={classes.leftColumnMenu}>
                        <Tooltip placement={'right'} title={<Typography>{user.data.name}</Typography>} arrow>
                            <MenuItem selected={userMenuOpen} onClick={() => setUserMenuOpen(true)}>
                                <ListItemAvatar>
                                    <Badge
                                        overlap={'circular'}
                                        color={'secondary'}
                                        badgeContent={user.data.unread_notifications_count}
                                        max={99}
                                    >
                                        <Avatar alt={user.data.name} src={user.data.avatar_route ?? userAvatar}>
                                            {user.data.name}
                                        </Avatar>
                                    </Badge>
                                </ListItemAvatar>
                            </MenuItem>
                        </Tooltip>
                        <Tooltip placement={'right'} title={<Typography>Admin Menu</Typography>} arrow>
                            <MenuItem onClick={() => setUserMenuOpen(false)} selected={!userMenuOpen}>
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            backgroundColor: (theme) => theme.palette.error.main,
                                        }}
                                    >
                                        <AdminPanelSettings sx={{ fontSize: 28 }} />
                                    </Avatar>
                                </ListItemAvatar>
                            </MenuItem>
                        </Tooltip>
                    </MenuList>
                )}
            </div>
            <div className={classes.rightColumn}>
                {user.data && userMenuOpen ? (
                    <UserSidebar {...{ user: user.data, darkMode: user.settings.darkMode, toggleDrawer }} />
                ) : (
                    <AdminMenu {...{ toggleDrawer }} />
                )}
            </div>
        </Stack>
    );
};

const useStyles = makeStyles<{ isMobile: boolean; isSmall: boolean }>()((theme, { isMobile, isSmall }) => ({
    sidebar: {
        height: '100%',
        borderTopRightRadius: theme.spacing(1),
        marginRight: isMobile || isSmall ? 0 : theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[8],
    },
    leftColumn: {
        width: 64,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 0,
            display: 'none',
        },
        scrollbarWidth: 'none',
    },
    // leftColumnBottom: {
    //     display: 'flex',
    //     flex: 1,
    //     alignItems: 'end',
    //     justifyContent: 'center',
    //     marginBottom: theme.spacing(1),
    // },
    leftColumnMenu: {
        marginTop: 0,
        paddingTop: 0,
        '& .MuiButtonBase-root': {
            paddingLeft: theme.spacing(1.5),
        },
    },
    rightColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: 300,
        height: '100%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 0,
            display: 'none',
        },
        scrollbarWidth: 'none',
    },
}));

export default AdminSidebar;
