import { FC, useEffect } from 'react';
import { Button } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { persistor } from '@/redux/store';
import { useAppDispatch } from '@/redux/hooks';
import { useLogoutMutation } from '@/redux/features/authApi';
import { logout as userLogout } from '@/redux/reducers/userSlice';
import { logout as authLogout } from '@/redux/reducers/authSlice';
import apiSlice from '@/redux/apiSlice';

const LogoutButton: FC = () => {
    const dispatch = useAppDispatch();
    const [logoutTrigger, { isSuccess }] = useLogoutMutation();

    const logoutHandler = () => {
        dispatch(userLogout());
        dispatch(authLogout());
        dispatch(apiSlice.util.resetApiState());
        persistor.purge();
        persistor.persist();
    };

    const logout = () => {
        if (import.meta.env.VITE_AUTH_FLOW === 'pkce') {
            console.log('logout pkce');
            logoutHandler();
        } else {
            console.log('logout auth');
            logoutTrigger();
        }
    };

    useEffect(() => {
        if (isSuccess) {
            logoutHandler();
        }
    }, [isSuccess]);

    return (
        <Button sx={{ py: 1.5 }} onClick={logout} size={'large'} fullWidth startIcon={<Logout />}>
            Logout
        </Button>
    );
};

export default LogoutButton;
