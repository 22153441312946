import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ModelPortalState = {
    recentColors: [],
};

const modelPortalSlice = createSlice({
    name: 'modelPortal',
    initialState,
    reducers: {
        addRecentColor: (state, action: PayloadAction<string>) => {
            const newColor = action.payload.toLowerCase();

            if (state.recentColors.length === 0) {
                state.recentColors = [newColor];
                return;
            }

            const exists = state.recentColors.find((item) => item === newColor);

            if (!exists) {
                state.recentColors = [newColor, ...state.recentColors];
            }

            if (state.recentColors.length > 21) {
                state.recentColors = state.recentColors.slice(0, 21);
            }
        },
    },
});

export default modelPortalSlice;
// eslint-disable-next-line no-empty-pattern
export const { addRecentColor } = modelPortalSlice.actions;
