import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { store } from '@/redux/store';

const disabled = import.meta.env.VITE_DISABLE_WEBSOCKETS === 'true';
console.log('uri', import.meta.env.VITE_URI);
const options = {
    broadcaster: disabled ? 'null' : 'reverb',
    client: disabled
        ? null
        : new Pusher(`${import.meta.env.VITE_PUSHER_APP_KEY}`, {
              cluster: 'NaN',
              wsPath: import.meta.env.VITE_PUSHER_APP_PATH,
              wsHost: import.meta.env.VITE_URI,
              wssPort: parseInt(import.meta.env.VITE_PUSHER_APP_WSS_PORT as string),
              wsPort: parseInt(import.meta.env.VITE_PUSHER_APP_WS_PORT as string),
              forceTLS: (import.meta.env.VITE_DEPLOY_ENV as string) !== 'local',
              enableStats: false,
              enabledTransports: ['ws', 'wss'],
              channelAuthorization: {
                  transport: 'ajax',
                  endpoint: `${import.meta.env.VITE_SCHEMA}://${import.meta.env.VITE_URI}${
                      import.meta.env.VITE_PORT ? `:${import.meta.env.VITE_PORT}` : ''
                  }/${import.meta.env.VITE_PREFIX}/broadcasting/auth`,
                  headersProvider: () => {
                      const state = store.getState();
                      if (state.auth.xsrfToken && state.auth.status) {
                          return {
                              Accept: 'application/json',
                              'X-XSRF-TOKEN': `${state.auth.xsrfToken}`,
                          };
                      }
                      if (state.auth.pkce.accessToken && state.auth.status) {
                          return {
                              Accept: 'application/json',
                              Authorization: `Bearer ${state.auth.pkce.accessToken}`,
                          };
                      }
                  },
              },
          }),
};

let echo: Echo = new Echo(options);

const listener = () => {
    const auth = store.getState().auth;

    if (auth.xsrfToken && auth.status) {
        // console.log('store', auth);
        echo = new Echo(options);
    }
    if (!auth.status) {
        echo.disconnect();
    }
};

store.subscribe(listener);

export default echo;
